import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Grid, useMediaQuery } from '@mui/material';

import FanGoBody from '../components/common/body/FanGoBody';
import CustomGridCarousel from '../components/common/carousel/CustomGridCarousel';
import CustomFanGoCard from '../components/common/fanGoCard/CustomFanGoCard';
import { FanGoTextProcessor } from '../components/common/portableText';
import StandardSEO from '../components/common/seo/StandardSEO';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import { DataContext } from '../providers/DataProvider';

const responsiveSizes = [
  {
    breakpoint: 500,
    cols: 1,
    rows: 11,
  },
];

const IntegrationsAndPartnersPage = ({ data }) => {
  const { sanityIntegrationsAndPartnersPage } = data;
  const {
    pageHead,
    integrationPartners: cards,
    paragraphOne,
  } = sanityIntegrationsAndPartnersPage;
  const { title: pageTitle, _rawSubTitle: pageSubtitle } = pageHead;
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const { _rawBody: paragraphBodyText } = paragraphOne;

  const isMobile = useMediaQuery(`(max-width: 480px)`);

  const cardsArr = [];
  cards.forEach((item, index) => {
    const { image, _key, title, text, url = '' } = item;
    const { asset, altText } = image;
    const imgData = asset?.gatsbyImageData;
    const card = (
      <CustomFanGoCard
        minHeight="200px"
        cardMaxWidth="200px"
        minCardWidth="200px"
        key={_key}
        title={title}
        image={imgData}
        altText={altText}
        text={text}
        imgPaddingTop={10}
        cardBackgroundColor="#FFFFFF"
        titleTextAlign="center"
        textContainerPaddingTop={20}
        link={url}
        externalLink
      />
    );
    cardsArr.push(card);
  });

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="0% 50%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={pageTitle}
            subTitle={pageSubtitle}
            titleRowSpacing={undefined}
            titlePaddingBottom={30}
            largeSubTitleSize
          />
        }
      />
      <FanGoBody>
        <FanGoTextProcessor
          text={paragraphBodyText}
          textAlign="center"
          variant="subtitle1"
        />
      </FanGoBody>
      <FanGoBody paddingTop="0px">
        <div style={{ paddingTop: 8 }}>
          {!isMobile && (
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'center', rowGap: 2 }}
            >
              {cardsArr.map((card) => {
                const cardUid = uuidv4();
                return (
                  <Grid key={cardUid} item sm={4} md={3}>
                    {card}
                  </Grid>
                );
              })}
            </Grid>
          )}
          {isMobile && (
            <CustomGridCarousel
              itemsArray={cardsArr}
              responsiveSizes={responsiveSizes}
              paddingBottom={2}
              speed={500}
            />
          )}
        </div>
      </FanGoBody>
    </>
  );
};

export default IntegrationsAndPartnersPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityIntegrationsAndPartnersPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityIntegrationsAndPartnersPage {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        _rawSubTitle
        background {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
          }
        }
      }
      integrationPartners {
        title
        text
        url
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 100, formats: [WEBP])
          }
        }
      }
      paragraphOne {
        _rawBody
      }
    }
    sanityHomePage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
